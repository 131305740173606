<template>
    <div class="container">
        <navbar/>
        <div class="image-container">
            <img class="image-header" :src="require('@/assets/LandingPage/LinzMarkt_header1.jpg')" alt="">
        </div>
        <div class="filters-horizontal">
            <div class="filters-search-bar">
                <img class="filters-search-icon" :src="require('@/assets/StändeView/magnifier.svg')" @click="search()" alt="">
                <input type="text" placeholder="Search" class="filters-search-input" v-model="searchText" @keyup.enter="search()">
            </div>
            <div v-for="button in horizontalButtons" :key="button.name" @click="applyFilterH(button.id)" :id="button.id" class="filter-horizonzal-buttons">{{button.name}}</div>
        </div>
        <div class="displayer">
            <div class="filters-vertical">
                <div class="item" v-for="filter in filters" :key="filter.name" @click="applyFilterV(filter.id)">
                    <img :id="filter.id" class="item-icon" :src="require('@/assets/StändeView/'+filter.icon+'.svg')" alt="">
                    <span class="item-text">{{filter.name}}</span>
                </div>
            </div>
            <div class="results">
                <div v-for="elem in showing" :key="elem.data.properties.id">
                    <card :data="elem.data.properties"
                          :currentDate="getCurrentDate()"
                          :img="elem.image"
                          :desc="elem.desc"
                          :contact="elem.contact"/>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .container {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative ;
    }
    /* --------------------------------------------------------- */
    /*                                                           */
    /*                                                           */
    /*                          DISPLAYER                        */
    /*                                                           */
    /*                                                           */
    /* --------------------------------------------------------- */
    .results {
        display: grid !important;
        grid-template-columns: repeat(3, auto);
        grid-template-rows: repeat(auto-fill, 40vh);
        gap: 1vw;
        overflow-y: scroll;
    }
    .displayer {
        display: flex;
        justify-content: center;
        margin-bottom: 2vh;
        height: 90vh;
    }
    .filters-vertical {
        display: flex;
        flex-direction: column;
        width: 25vw;
    }
    .results {
        display: flex;
        flex-direction: column;
        width: 65vw;
    }
    .item {
        display: flex;
        align-items: center;
        height: 5vh;
        margin-bottom: 2.5vh;
        cursor: pointer;
    }
    .item:last-child {
        margin-bottom: 2.5vh;
    }
    .item:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
    .item-icon {
        height: 100%;
        background-color: #104f47;
     }
    .item-text {
        font-size: 2vh;
        font-weight: 500;
        margin-left: .75vw;
        color: #104f47;
        text-align: start;
    }

    @media (max-width: 767px) and (max-height: 900px) {
        .results {
            row-gap: 11vh;
        }
    }

    @media (max-width: 767px) and (max-height: 800px) {
        .results {
            row-gap: 12vh;
        }
    }

    @media (max-width: 767px) and (max-height: 700px) {
        .results {
            row-gap: 14vh;
        }
    }

    @media (max-width: 767px) and (max-height: 600px) {
        .results {
            row-gap: 18vh;
        }
    }

    @media (max-width: 767px) and (max-height: 600px) {
        .results {
            row-gap: 25vh;
        }
    }

    @media (max-width: 767px) and (max-height: 500px) {
        .results {
            row-gap: 27vh;
        }
    }

    @media (max-width: 767px) {
        .results {
            grid-template-columns: repeat(2, auto);
            grid-template-rows: repeat(auto-fill, 25vh);
        }
        .item {
            height: 3vh;
            margin-bottom: 2vh;
        }
        .item-text {
            font-size: 2.5vw;
        }
        .image-container {
            display: none;
        }
        .filters-vertical {
            width: 24vw;
            margin-right: 10px;
    }
    }

    @media (min-width: 768px) and (max-width: 1023px) {
        .results {
            grid-template-columns: repeat(3, auto);
            grid-template-rows: repeat(auto-fill, 25vh);
            gap: 1vw;
        }
        .item {
            height: 3.25vh;
            margin-bottom: 2vh;
        }
        .item-text {
            font-size: 1.3vw;
        }
    }

    @media (min-width: 1024px) and (orientation: landscape) {
        .results {
            grid-template-columns: repeat(3, auto);
            grid-template-rows: repeat(auto-fill, 25vh);
            gap: 1vw;
            row-gap: 15vh;
        }
        .item {
            height: 3.75vh;
            margin-bottom: 2vh;
        }
        .item-text {
            font-size: 1.3vw;
        }
    }

    @media (min-width: 1200px) and (orientation: landscape) {
        .results {
            grid-template-columns: repeat(3, auto);
            grid-template-rows: repeat(auto-fill, 25vh);
            gap: 1.5vw;
            row-gap: 10vh;
        }
        .item {
            height: 3.75vh;
            margin-bottom: 2vh;
        }
        .item-text {
            font-size: 1.3vw;
        }
    }
    /* --------------------------------------------------------- */
    /*                                                           */
    /*                                                           */
    /*                       HEADER IMAGE                        */
    /*                                                           */
    /*                                                           */
    /* --------------------------------------------------------- */
    .image-header {
        object-fit: cover;
        width: 100%;
        height: auto;
        margin: 0;
    }
    .image-container {
        position: relative;
        text-align: center;
        color: white;
        margin: 0;
    }
    /* --------------------------------------------------------- */
    /*                                                           */
    /*                                                           */
    /*                    HORIZONATAL FILTERS                    */
    /*                                                           */
    /*                                                           */
    /* --------------------------------------------------------- */
    .filters-horizontal {
        display: flex;
        height: 5vw;
        width: 100%;
        /* background-color: red; */
        justify-content: center;
        align-items: center;
        margin-bottom: 2vh;
        cursor: default;
    }
    .filters-search-bar {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 25vw;
    }
    .filters-search-input {
        height: 60%;
        width: 100%;
        border: gray .5px solid;
        border-left: none;
        border-radius: 0;
        font-size: 2vh;
        padding-left: 5px;
        outline: none;
        padding: 1px 2px;
    }
    .filters-search-icon {
        padding: 1px 2px;
        height: 60%;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        border: gray .5px solid;
        border-right: none;
        cursor: pointer;
    }
    .filter-horizonzal-buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #104f47;
        border: #104f47 .5px solid;
        color: white;
        font-size: 3vh;
        height: 60%;
        padding: 1px 2px;
        width: 20vw;
        cursor: pointer;
    }
    .filter-horizonzal-buttons:hover {
        background-color: #45845e;
        border: #45845e .5px solid;
    }
    .filter-horizonzal-buttons:last-child {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
    }

    @media (max-width: 767px) {
        .filter-horizonzal-buttons {
            font-size: 2.5vw;
        }
        .filters-search-input {
            font-size: 2vh;
        }
        .filters-horizontal {
            height: 10vw;
        }
    }

    @media (min-width: 768px) and (max-width: 1023px){
        .filter-horizonzal-buttons {
            font-size: 1.75vw;
        }
        .filters-search-input {
            font-size: 2vh;
        }
        .filters-horizontal {
            height: 7vw;
        }
    }

    @media (min-width: 1024px) and (orientation: landscape){
        .filter-horizonzal-buttons {
            font-size: 1.5vw;
        }
        .filters-search-input {
            font-size: 2vh;
        }
        .filters-horizontal {
            height: 6vw;
        }
    }

    @media (min-width: 1200px) and (orientation: landscape){
        .filter-horizonzal-buttons {
            font-size: 1.5vw;
        }
        .filters-search-input {
            font-size: 2vh;
        }
        .filters-horizontal {
            height: 6vw;
        }
    }
    @media (min-width: 900px) and (orientation: portrait) {
        .filter-horizonzal-buttons {
            font-size: 2vw;
        }
        .filters-search-input {
            font-size: 2vh;
        }
        .filters-horizontal {
            height: 6vw;
        }
        .displayer {
        height: 65vh;
        }
         .filters-vertical {
           height: auto;
           width: 35vw
         }
         .filters-vertical .item {
           margin-bottom: 2.75vw;
         }
         
         .filters-vertical .item-text {
           font-size: 3.2vw;
         }  
         .item-icon {
        height: 6vw;
       
        }
     
        .results {
       
        grid-template-columns: repeat(2, 31vw);
        grid-template-rows: repeat(auto-fill, 35vh);
      
         } 
    }    
</style>

<script>
    import card from '@/components/ItemCard.vue'
    import navbar from '@/components/LandingPageNavbar.vue'
    import { getLang } from '@/main'
    export default {
        components: {
            card,
            navbar
        },
        data() {
            return {
                filters:[{"name": this.$t('standPage.filters.blumen'), "icon": "Linz_Blumen_Pflanzen", "id": 294},
                         {"name": this.$t('standPage.filters.brot'), "icon": "Linz_Brot_Gebaeck", "id": 295},
                         {"name": this.$t('standPage.filters.cafe'), "icon": "Linz_Cafe_Konditorei_Mehlspeisen", "id": 296},
                         {"name": this.$t('standPage.filters.deko'), "icon": "Linz_Deko_und_Geschenksartikel_Handelswaren", "id": 297},
                         {"name": this.$t('standPage.filters.deinst'), "icon": "Linz_Dienstleistungen", "id": 301},
                         {"name": this.$t('standPage.filters.droge'), "icon": "Linz_Drogerie_Reformartikel", "id": 298},
                         {"name": this.$t('standPage.filters.fleisch'), "icon": "Linz_Fleisch_Wurst_Gefluegel_Fisch", "id": 299},
                         {"name": this.$t('standPage.filters.gastro'), "icon": "Linz_Gastro", "id": 202},
                         {"name": this.$t('standPage.filters.imbiss'), "icon": "Linz_Imbiss", "id": 302},
                         {"name": this.$t('standPage.filters.inter'), "icon": "Linz_Internationale_Spezialitaeten", "id": 303},
                         {"name": this.$t('standPage.filters.leben'), "icon": "Linz_Lebensmittel", "id": 304},
                         {"name": this.$t('standPage.filters.obst'), "icon": "Linz_Obst_Gemuese", "id": 306}],
                horizontalButtons: [{"name": this.$t('standPage.horizontalButtons.fix'), "id": 347},
                                    {"name": this.$t('standPage.horizontalButtons.mobile'), "id": 346},
                                    {"name": this.$t('standPage.horizontalButtons.today'), "id": -1}],
                days : [this.$t('standPage.days.sunday'),
                        this.$t('standPage.days.monday'),
                        this.$t('standPage.days.tuesday'),
                        this.$t('standPage.days.wednesday'),
                        this.$t('standPage.days.thursday'),
                        this.$t('standPage.days.friday'),
                        this.$t('standPage.days.saturday')],
                clickedButtons: new Set(),
                clickedHButton: undefined,
                showing: [],
                stands: [],
                source: [],
                searchText: ''
            }
        },
        methods: {
            applyFilterH(id) {
                document.getElementById(347).style.backgroundColor = '#104f47'
                document.getElementById(346).style.backgroundColor = '#104f47'
                document.getElementById(-1).style.backgroundColor = '#104f47'
                if(this.clickedHButton != id) {
                    this.clickedHButton = id
                    document.getElementById(id).style.backgroundColor = '#45845e'
                    if(id == -1) this.getCurrentStands()
                    else {
                        const aux = this.stands.filter(stand => {
                            if(stand.type.includes(id))
                                return true
                            return false
                        })
                        this.source = aux
                        this.showing = this.source
                    }
                } else {
                    this.clickedHButton = undefined
                    this.source = this.stands
                    this.showing = this.source
                }
            },
            applyFilterV(id) {
                if(this.clickedButtons.has(id)) {
                    document.getElementById(id).style.backgroundColor = '#104f47'
                    this.clickedButtons.delete(id)
                } else {
                    this.clickedButtons.add(id)
                    document.getElementById(id).style.backgroundColor = '#45845e'
                }
                if(this.clickedButtons.size == 0) this.showing = this.source
                else {
                    const aux = this.source.filter(stand => {
                        for(const cbid of this.clickedButtons)
                            if(stand.type.includes(cbid))
                                return true
                        return false
                    })
                    this.showing = aux
                }
            },
            getCurrentDate() {
                const d = new Date();
                return this.days[d.getDay()].toLowerCase();
            },
            deleteItem(id) {
                this.showing = this.showing.splice(this.showing.findIndex(id), 1)
            },
            search() {
                if(this.searchText === '') this.showing = this.stands
                else {
                    var filteredObjects = this.stands.filter( elem => {
                        if(elem.data.properties.name.toLowerCase().includes(this.searchText.toLowerCase())){
                            return true
                        }
                        return false
                    });
                    this.showing = filteredObjects
                }
            },
            getCurrentStands() {
                const aux = this.stands.filter(stand => {
                    if(stand.data.properties.hoursOfOperation) {
                        var weekDay = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
                        const today = new Date()
                        const hs = today.getHours();
                        const min = today.getMinutes();
                        var currentDay = weekDay[today.getDay()];
                        var workDay = stand.data.properties.hoursOfOperation[0][currentDay]
                        if(workDay) {
                            if(workDay[0].closed)
                                return false
                            else {
                                if(stand.data.properties.tags.objectType.includes(346) && !stand.data.properties.liveData.checkIn)
                                    return false
                                if(stand.data.properties.tags.objectType.includes(347) && stand.data.properties.liveData.status != 169)
                                    return false
                                const [whs, wmin] = workDay[0].to.split(':')
                                if(hs > whs)
                                    return false
                                if(hs == whs && min > wmin)
                                    return false
                                return true
                            }
                        } else {
                            return false
                        }
                    }
                })
                this.showing = aux
            }
        },
        created() {
            this.axios.get('https://srm.intermaps.com/admin/api/srm_api_list_v2.aspx?resortId=5265&category=2&objectType=346,347&lang=' + getLang() + '&kindOf=details')
                    .then(response => {
                        response.data.features.forEach((elem) => {
                            if(elem.properties.images) {
                                const img = elem.properties.images[0].url
                                const objectCard = {data: elem,
                                                    image: img,
                                                    contact: elem.properties.contactDetails,
                                                    desc: elem.properties.description ? elem.properties.description : this.$t('landingPage.noDescriptionStand'),
                                                    type: elem.properties.tags.objectType}
                                this.stands.push(objectCard)
                                this.source.push(objectCard)
                                this.showing.push(objectCard)
                            }
                        })
                    }).catch(e => console.log("Error: " + e))
        }
    }
</script>