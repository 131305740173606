import Vue from 'vue'
import VueRouter from 'vue-router'
import LandingPage from '../views/LandingPage.vue'
import MapViewer from '../views/MapViewer.vue'
import StändeView from '../views/StändeView.vue'
import InfoView from '../views/InfoView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage
  },
  {
    path: '/map',
    name: 'MapViewer',
    component: MapViewer
  },
  {
    path: '/stands',
    name: 'Stand',
    component: StändeView
  },
  {
    path: '/info',
    name: 'Info',
    component: InfoView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
