import { render, staticRenderFns } from "./DownloadPopup.vue?vue&type=template&id=92d74ea2&"
import script from "./DownloadPopup.vue?vue&type=script&lang=js&"
export * from "./DownloadPopup.vue?vue&type=script&lang=js&"
import style0 from "./DownloadPopup.vue?vue&type=style&index=0&id=92d74ea2&prod&scopet=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports