import { render, staticRenderFns } from "./ItemCarouselCard.vue?vue&type=template&id=a3a1b57a&scoped=true&"
import script from "./ItemCarouselCard.vue?vue&type=script&lang=js&"
export * from "./ItemCarouselCard.vue?vue&type=script&lang=js&"
import style0 from "./ItemCarouselCard.vue?vue&type=style&index=0&id=a3a1b57a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3a1b57a",
  null
  
)

export default component.exports