<template>
    <div class="card" @click="cardClick()">
        <img :src="src" alt="" class="card-img">
        <div class="card-title">{{ title }}</div>
        <div class="card-subtitle">{{ subtitle }}</div>
        <div class="popup" v-if="clicked">
            <div class="popup-card">
                <div class="popup-card-title">
                    <div class="title">{{$t('itemCarouselCard.description')}}</div>
                    <div class="img">
                        <img src="https://s3-eu-west-1.amazonaws.com/intermaps-icons/intermaps/icon_close_3@1x.png" alt="">
                    </div>
                </div>
                <div class="popup-card-img">
                    <img :src="src" class="card-img">
                </div>
                <div class="popup-card-content">
                    <div v-if="storeName" class="storeName">{{ this.storeName }}</div>
                    <div class="content">{{ description }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            storeId: Number,
            src: String,
            title: String,
            subtitle: String,
            description: String
        },
        data() {
            return {
                clicked: false,
                storeName: ''
            }
        },
        methods: {
            cardClick() {
                this.clicked = !this.clicked
                if(this.storeId > 0)
                    this.axios.get('https://srm.intermaps.com/admin/api/srm_api_list_v2.aspx?id='+ this.storeId +'&kindOf=details')
                        .then(response => {
                            console.log(response.data)
                            this.storeName = response.data.features[0].properties.name
                        })
            }
        }
    }
</script>

<style scoped>
    .storeName {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #86CBCB;
        margin-top: 1vh;
        height: 3vh;
        width: 100%;
        padding: 5px 0;
        font-weight: 450;
    }
    .popup-card-img {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30vh;
        width: 100%;
    }
    .content {
        display: flex;
        justify-content: center;
        margin-top: .5vh;
        width: 95%;
        height: 100%;
        overflow-y: scroll;
        text-align: justify;
    }
    .popup-card-content {
        width: 100%;
        height: 30vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .img {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .img:hover {
        background-color: rgba(0, 0, 0, .2);
    }
    .title {
        display: flex;
        align-items: center;
        padding-left: 1vw;
        font-size: 2vw;
        font-weight: 600;
        color: #104f47;
    }
    .popup-card-title {
        display: grid;
        grid-template-columns: 85% 15%;
        grid-template-rows: auto;
        height: 10vh;
        width: 100%;
    }
    .popup-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 70vh;
        width: 40vw;
        background-color: white;
    }
    .popup {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 10;
        background-color: rgba(0, 0, 0, .2);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;
    }
    .card {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        width: 30vw;
        height: 35vh;
        background-color: #fff;
        margin-right: 3vw;
        cursor: pointer;
    }
    .card-img {
        max-width: auto;
        height: 30vh;
        object-fit:cover;
        resize: none;
    }
    .card-title {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        text-align: start;
        height: 2vw;
        font-size: 1.25rem;
        font-weight: 550;
        margin-top: 5px;
    }
    .card-subtitle {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 1.25vw;
        font-weight: 450;
        color: #45845e;
    }

    @media (max-width: 767px) {
        /* --------------------------------------------------------- */
        /*                                                           */
        /*                                                           */
        /*                            CARD                           */
        /*                                                           */
        /*                                                           */
        /* --------------------------------------------------------- */
        .card { height: 33vh;
            width: 45vw;
            word-wrap: break-word;
            overflow-wrap: break-word;
             }
        .card-img { height: 25vh; }
        .card-title {
            height: 3rem;
            font-size: 1rem;

        }
        .card-subtitle { font-size: 0.8rem; margin-top: 3px;}

        .description { font-size: 2vw;}
        .popup-card {
            width: 90vw;
        }
        .title {
            font-size: 2.5vh;
            padding-left: 2.4vw;
        }
    }

    @media (min-width: 768px) and (max-width: 1023px) {
        /* --------------------------------------------------------- */
        /*                                                           */
        /*                                                           */
        /*                            CARD                           */
        /*                                                           */
        /*                                                           */
        /* --------------------------------------------------------- */
        .card { height: 37vh; }
        .card-img { height: 30vh; }
        .card-title {
            height: 4vh;
            font-size: 1.6vh;
        }
        .card-subtitle { font-size: 1.5vw; }
        .description { font-size: 2vw;}
        .popup-card {
            width: 70vw;
        }
        .title {
            font-size: 3vh;
            padding-left: 2.4vw;
        }
    }

    @media (min-width: 1024px) and (orientation: landscape) {
        /* --------------------------------------------------------- */
        /*                                                           */
        /*                                                           */
        /*                            CARD                           */
        /*                                                           */
        /*                                                           */
        /* --------------------------------------------------------- */
        .card { height: 37vh; }
        .card-img { height: 30vh; }
        .card-title {
            height: 2vh;
            font-size: 1.75vw;
        }
        .card-subtitle { font-size: 1.5vw; }
        .description { font-size: 2vw;}
        .popup-card {
            width: 60vw;
        }
        .title {
            font-size: 3.5vh;
            padding-left: 2.4vw;
        }
    }

    @media (min-width: 1200px) and (orientation: landscape) {
        /* --------------------------------------------------------- */
        /*                                                           */
        /*                                                           */
        /*                            CARD                           */
        /*                                                           */
        /*                                                           */
        /* --------------------------------------------------------- */
        .card { height: 37vh; }
        .card-img { height: 30vh; }
        .card-title {
            height: 2vh;
            font-size: 1.5vw;
        }
        .card-subtitle {
            font-size: 1.5vw;
            margin-top: 5px; }
        .description { font-size: 2vw;}
        .popup-card {
            width: 60vw;
        }
        .title {
            font-size: 3.5vh;
            padding-left: 2.4vw;
        }
    }

    @media (min-width: 900px) and (orientation: portrait) {
        .card-title {
            height: 50px;
            font-size: 30px;
            margin-top: 10px;
        }
        .card-subtitle {
            font-size: 3vw;
            margin-top: 20px;
        }

        .popup-card {
            width: 70vw;
        }
        .popup-card-img .card-img { width: 70vw;}
        .popup-card-content {font-size: 3vw;}
        .popup-card-content .content {font-size: 2.5vw;}
        .popup-card-title .title{font-size: 3vw;}
    }


</style>