<template>
  <div id="app">
    <downloadPopup v-if="this.screenWidth < 1000"/>
    <router-view style="overflow-y: scroll;"/>
    <footerComponent/>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
}
</style>

<script>
import downloadPopup from '@/components/DownloadPopup.vue'
import footerComponent from '@/components/FooterComponent.vue'
import { setLang, setKiosk } from '@/main'

export default {
  name: 'LandingPage',
  components: {
    downloadPopup,
    footerComponent
  },
  data() {
    return {
      screenWidth: window.innerWidth
    }
  },
  created() {
      const queryParams = new URLSearchParams(window.location.search);
      const lang = queryParams.get("lang");
      const useParam = queryParams.get("kiosk");
      setLang(lang ? lang : 'de');
      this.setLocale(lang ? lang : 'de');
      setKiosk(useParam);

  },
  mounted() {
      this.$nextTick(() => {
          window.addEventListener('resize', this.onResize);
      })
  },

  beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
  },

  methods: {
      onResize() {
          this.screenWidth = window.innerWidth
      },
      setLocale(lang) {
          this.$i18n.locale = lang
      }
  }
}
</script>
