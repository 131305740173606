<template>
    <div class="navbar">
        <div class="navbar-column">
            <img class="brand" @click="goTo('/')" :src="require('@/assets/LandingPageNavbar/client_logo.svg')" alt="">
        </div>
        <div class="navbar-column">
            <div class="navbar-links" v-if="this.screenWidth > 500">
                <div v-for="link in links" :key="link.name" @click="goTo(link.to)"><span class="link">{{ link.name }}</span></div>
            </div>
            <div v-else class="dropdown">
                <img id="burger" class="burger" :src="require('@/assets/LandingPageNavbar/menu.svg')" @click="displaySubmenu" alt="">
                <div id="ddc" class="dropdown-content">
                    <div v-for="link in links" :key="link.name" @click="goTo(link.to)"><span>{{ link.name }}</span></div>
                </div>
            </div>
        </div>
        <div class="navbar-column" v-if="this.screenWidth > 1000 && !isKiosk" >
            <div class="navbar-imgs">
                <object type="image/svg+xml" :data="require('@/assets/LandingPageNavbar/facebook.svg')"></object>
                <object type="image/svg+xml" :data="require('@/assets/LandingPageNavbar/instagram.svg')"></object>
                <object type="image/svg+xml" :data="require('@/assets/LandingPageNavbar/download.svg')"></object>
            </div>
        </div>
    </div>
</template>

<style scoped>
.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    position: relative;
    padding: 0 1.5vw;
    height: 6vh;
    margin: 0;
    background-color: #104f47;
}
.navbar-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 6vh;
}
.navbar-imgs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.navbar-imgs object {
    height: 5.25vh;
}

.navbar-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
}

.navbar-links div {
    color: white;
    font-weight: 500;
    font-size: 1.05vw;
    margin: 0 1.25vw;
    text-transform: uppercase;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 .75vw;
}

.navbar-links div span {
    margin-bottom: .5vw;
}

.navbar-links div:hover {
    background-color: #45845e;
}

.navbar-imgs object {
    margin-right: .5vw;
    width: 2vw;
}

.navbar-imgs object:last-child {
    margin-right: 0;
    width: 14vw;
    max-width:230px;
    max-height:95px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  top: 5vh;
  text-transform: uppercase;
}

/* Links inside the dropdown */
.dropdown-content a,
.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-bottom: black 1px solid;
}

.brand {
    cursor: pointer;
    height: 5.25vh;
    width: 100%;
}

.dropdown-content div:last-child { border-bottom: none; }

/* Change color of dropdown links on hover */
.dropdown-content div:hover {background-color: #ddd;}


/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {background-color: #45845e;}
@media (max-height: 767px) {
    .brand {
        width: 30vw;
        height: 100%;
    }
}

@media (max-width: 500px) {
    .burger {
        height: auto;
        width: 50%;
    }
}

@media (max-width: 767px) {
    .brand {
        width: 50vw;
        height: 6vh;
    }
    .navbar {
        height: 6vh;
    }
    .navbar .navbar-column:first-child {
        justify-content: center;
    }
    .link{
        font-size: 2vh;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .brand {
        width: 20vw;
        height: auto;
    }
    .navbar-links a {
        color: white;
        font-weight: 500;
        font-size: 1.5vw;
        margin: 0 1.25vw;
        text-transform: uppercase;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0 .75vw;
        padding-bottom: 1vw;
        margin-bottom: 1vw;
        text-decoration: none;
    }
    .navbar-links div {
        color: white;
        font-weight: 500;
        font-size: 1.5vw;
        margin: 0 1.25vw;
        text-transform: uppercase;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0 .75vw;
        padding-bottom: 1vw;
    }

    .link {
        font-size: 2vh;
    }

}

@media (min-width: 1024px) and (orientation: landscape) {
    .brand {
        width: 22vw;
        height: auto;
    }
    .navbar-links a {
        color: white;
        font-weight: 500;
        font-size: 1.5vw;
        margin: 0 1.25vw;
        text-transform: uppercase;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0 .75vw;
        padding-bottom: 1vw;
        margin-bottom: 1vw;
        text-decoration: none;
    }
    .navbar-links div {
        color: white;
        font-weight: 500;
        font-size: 1.5vw;
        margin: 0 1.25vw;
        text-transform: uppercase;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0 .75vw;
        padding-bottom: 1vw;
    }

    .link {
        font-size: 2vh;
    }
}

@media (min-width: 1200px) and (orientation: landscape) {
    .brand {
        width: 22vw;
        height: auto;
    }
    .navbar-links a {
        color: white;
        font-weight: 500;
        font-size: 1.5vw;
        margin: 0 1.25vw;
        text-transform: uppercase;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0 .75vw;
        padding-bottom: 1vw;
        margin-bottom: 1vw;
        text-decoration: none;
    }
    .navbar-links div {
        color: white;
        font-weight: 500;
        font-size: 1.5vw;
        margin: 0 1.25vw;
        text-transform: uppercase;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0 .75vw;
        padding-bottom: 1vw;
    }

    .link {
        font-size: 2vh;
    }


}
@media (min-width: 900px) and (orientation: portrait) {
        .navbar-links div  {
            font-size: 2vw;
        }
    }

</style>

<script>
    import { getKiosk } from '@/main'
    export default {
        data() {
            return {
                links: [{"name": this.$t('links.map'), "to": '/map'},
                        {"name": this.$t('links.stand'), "to": '/stands'}],
                screenWidth: window.innerWidth,
                showing: false,
                isKiosk: false
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.isKiosk = getKiosk();
                window.addEventListener('resize', this.onResize);
            })
        },

        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },

        methods: {
            onResize() {
                this.screenWidth = window.innerWidth
            },
            goTo(to) {
                this.$router.push({path: to}).catch(()=>{});
            },
            redirectTo(to) {
                window.location.href = to
            },
            displaySubmenu() {
                const dropdownContent = document.getElementById('ddc');
                if(!this.showing) {
                    dropdownContent.style.display = 'block';
                    this.showing = true
                } else {
                    dropdownContent.style.display = 'none';
                    this.showing = false
                }
            }
        }
    }
</script>