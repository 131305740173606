<template>
    <div class="container">
        <navbar/>
        <!-- Landing page header picture -->
        <div class="image-container">
            <img class="image-header" :src="require('@/assets/LandingPage/LinzMarkt_header1.jpg')" alt="">
            <div class="image-text-overlay">
                <div class="image-text-overlay-title">{{ $t('landingPage.welcome') }}</div>
                <div class="image-text-overlay-subtitle">{{ $t('landingPage.headerText') }}</div>
            </div>
        </div>
        <!-- Highlights -->
        <itemCarousel :title="$t('landingPage.highlights')" :data="this.highlights" v-if="highlights.length"/>
        <itemCarousel :title="$t('landingPage.events')" :data="this.events" v-if="events.length"/>
        <itemCarousel :title="$t('landingPage.today')" :data="this.today" v-if="today.length"/>
    </div>
</template>

<style scoped>
    .container {
        display: flex;
        flex-direction: column;
        height: 100%;
        overscroll-behavior: auto;
    }
    .image-header {
        object-fit: cover;
        width: 100%;
        height: auto;
        margin: 0;
    }
    .image-container {
        position: relative;
        text-align: center;
        color: white;
        margin: 0;
    }
    .image-text-overlay {
        position: absolute;
        text-align: justify;
        width: 35%;
        left: 10%;
        top: 35%;
    }
    .image-text-overlay-title {
        text-align: start;
        font-size: 3vw;
        text-size-adjust: auto;
        font-weight: 600;
    }
    .image-text-overlay-subtitle {
        font-size: 2vw;
        font-weight: 500;
    }

    @media (max-width: 500px) {
        .image-text-overlay {
            position: absolute;
            text-align:left;
            width: 45%;
            left: 5%;
            top: 15%;
        }
        .image-text-overlay-title {
            text-align: start;
            font-size: 3vw;
        }
        .image-text-overlay-subtitle {
            font-size: 2.5vw;
            font-weight: 500;
        }
    }

    @media (min-width: 900px) and (orientation: portrait) {
        .image-text-overlay {
        position: absolute;
        text-align: left;
        width: 40%;
        left: 5%;
        top: 5%;
    }
        .image-text-overlay-title {
            font-size: 3.5vw;
        }
        .image-text-overlay-subtitle {
            font-size: 2.7vw;
            font-weight: 500;
        }
    }

</style>

<script>
// @ is an alias to /src
import itemCarousel from '@/components/ItemCarousel.vue'
import navbar from '@/components/LandingPageNavbar.vue'
import { getLang } from '@/main'

export default {
  name: 'LandingPage',
  components: {
    itemCarousel,
    navbar
  },
  data() {
    return {
        screenWidth: window.innerWidth,
        highlights: [],
        events: [],
        today: []
    }
  },
  methods: {
    formatDate(inputDate) {
        const [year, month, day] = inputDate.split('-');
        const formattedDate = `${day}.${month}.${year}`;
        return formattedDate;
    }
  },
  created() {
    const lang = getLang()

    this.axios.get('https://srm.intermaps.com/admin/api/srm_api_list_v2.aspx?resortId=5265&category=1,2,3,10&objectType=348&lang=' + lang + '&kindOf=details')
            .then(response => {
                response.data.features.forEach(data => {
                    const p = data.properties.shopping
                    const a = p.preis ? '€' + p.preis + '/' + p.quantity + p.unit : this.$t('landingPage.checkPrice')
                    const description = data.properties.description ? data.properties.description : this.$t('landingPage.noDescriptionProduct')
                    if(data.properties.images)
                        this.highlights.push({id: data.properties.id,
                                            name: data.properties.name,
                                            img: data.properties.images[0].url,
                                            assignment: data.properties.tags.assignment ? data.properties.tags.assignment[0] : -1,
                                            info: a,
                                            desc: description})
                })
            })

    this.axios.get('https://srm.intermaps.com/admin/api/srm_api_list_v2.aspx?resortId=5265&category=1,2,3,10&objectType=349&lang=' + lang + '&kindOf=details')
            .then(response => {
                response.data.features.forEach(data => {
                    const p = data.properties.shopping
                    const a = p.preis ? '€' + p.preis + (p.quantity ?'/' + p.quantity + p.unit : '') : this.$t('landingPage.checkPrice')
                    const description = data.properties.description ? data.properties.description : ''
                    if(data.properties.images)
                        this.highlights.push({id: data.properties.id,
                                            name: data.properties.name,
                                            img: data.properties.images[0].url,
                                            assignment: data.properties.tags.assignment ? data.properties.tags.assignment[0] : -1,
                                            info: a,
                                            desc: description})
                })
            })

    this.axios.get('https://srm.intermaps.com/admin/api/srm_api_list_v2.aspx?resortId=5265&category=1,2,3,10&objectType=284&lang=' + lang + '&kindOf=details')
            .then(response => {
                response.data.features.forEach(data => {

                    const d = (data.properties.hoursOfOperation) ? data.properties.hoursOfOperation[0] : undefined
                    const dateText = d ? this.formatDate(d.dateFrom) === this.formatDate(d.dateTo) ? this.formatDate(d.dateFrom) : this.formatDate(d.dateFrom) + ' - ' + this.formatDate(d.dateTo) : ''
                    const description = data.properties.description ? data.properties.description : this.$t('landingPage.noDescriptionEvent')
                    const currentDate = new Date();
                    let old = true;
                    const eDate = new Date();

                    if(d) {
                        const [eyear, emonth, eday] = d.dateTo.split('-');
                        eDate.setYear(parseInt(eyear));
                        eDate.setMonth(parseInt(emonth));
                        eDate.setDate(parseInt(eday));
                        eDate.setHours(23);
                        if(eDate >= currentDate) {
                           old = false;
                        }
                    }
                    if(!old) {
                        this.events.push({id: data.properties.id,
                                        name: data.properties.name,
                                        img: data.properties.images[0].url,
                                        info: dateText,
                                        desc: description})
                        }
                })
            })
    this.axios.get('https://srm.intermaps.com/admin/api/srm_api_list_v2.aspx?resortId=5265&category=2&objectType=346,347&lang=' + lang + '&kindOf=details')
            .then(response => {
                response.data.features.forEach(data => {
                    const description = data.properties.description ? data.properties.description : this.$t('landingPage.noDescriptionStand')
                    let isCheckedIn = undefined, isOpen = undefined
                    if(data.properties.tags.objectType.includes(346))
                        isCheckedIn = data.properties.liveData.checkIn;
                    else if (data.properties.tags.objectType.includes(347))
                        isOpen = (data.properties.liveData.status == 169)
                    if(data.properties.images && (isCheckedIn || isOpen))
                        this.today.push({id: data.properties.id,
                                            name: data.properties.name,
                                            img: data.properties.images[0].url,
                                            info: '',
                                            desc: description})
                })
            })
  }
}
</script>