<template>
    <div class="dPopup" v-if="popup">
        <div class="close" @click="popup = false">x</div>
        <div class="logo">
            <img class="logo-img" :src="require('@/assets/DownloadPopup/appIcon.png')" alt="">
        </div>
        <div class="button">
            <object class="button-img" type="image/svg+xml" :data="require('@/assets/LandingPageNavbar/download.svg')"></object>
        </div>
    </div>
</template>

<style scopet>
.dPopup {
    height: 10vw;
    width: 100%;
    background-color: #45845e;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: grid;
    grid-template-columns: 10% 25% 65%;
}
.close {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.logo-img {
    width:30%;
    height:auto;
}
.button-img {
    width:50%;
    height:auto;
}
@media (max-width: 500px) {
    .dPopup {
        height: 20vw;
    }
    .logo-img {
        width:70%;
        height:auto;
    }
    .button-img {
        width:90%;
        height:auto;
    }
}
</style>

<script>
    export default {
        data() {
            return {
                popup: true
            }
        }
    }
</script>