<template>
    <div class="object-carousel">
        <div id="left" class="object-carousel-left" @click="scrollElements(-1)">
            <img :src="arrowLeft" class="object-carousel-arrow">
        </div>
        <div id="right" class="object-carousel-right" @click="scrollElements(1)">
            <img :src="arrowRight" class="object-carousel-arrow">
        </div>
        <div class="object-carousel-title">{{ this.title }}</div>
        <div :id="title" class="object-carousel-content">
            <card v-for="item in data" :key="item.id"
                :storeId="item.assignment"
                :src="item.img"
                :title="item.name"
                :subtitle="item.info"
                :description="item.desc"/>
        </div>
    </div>
</template>

<style scoped>
    /* --------------------------------------------------------- */
    /*                                                           */
    /*                                                           */
    /*                    OBJECT CAROUSEL                        */
    /*                                                           */
    /*                                                           */
    /* --------------------------------------------------------- */
    .object-carousel {
        display: grid;
        grid-template-columns: 5vw 90vw 5vw;
        grid-template-rows: 8vh 40vh;
    }
    .object-carousel-arrow {
        width: 90%;
        height: fit-content;
    }
    .object-carousel-arrow:hover {
        background: rgba(0, 0, 0, .2);
        border-radius: 50%;
    }
    .object-carousel-left {
        grid-row-start: 1;
        grid-row-end: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .object-carousel-right {
        grid-row-start: 1;
        grid-row-end: 3;
        grid-column-start: 3;
        grid-column-end: 4;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .object-carousel-title {
        grid-column: 2/3;
        grid-row: 1/2;
        display: flex;
        align-items: center;
        font-size: 2rem;
        font-weight: 600;
    }
    .object-carousel-content {
        grid-column: 2/3;
        grid-row: 2/3;
        display: flex;
        align-items: center;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    @media (max-width: 767px) {
        /* --------------------------------------------------------- */
        /*                                                           */
        /*                                                           */
        /*                    OBJECT CAROUSEL                        */
        /*                                                           */
        /*                                                           */
        /* --------------------------------------------------------- */
        .object-carousel {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 5vh 35vh;
            margin-left: 2vw;
        }
        .object-carousel-left { display: none; }
        .object-carousel-right { display: none; }
        .object-carousel-title {
            grid-column: 1;
            grid-row: 1/2;
            font-size: 1.5rem;
        }
        .object-carousel-content {
            grid-column: 1;
            grid-row: 2/3;
        }
    }
    @media (min-width: 768px) and (max-width: 1023px) {
        /* --------------------------------------------------------- */
        /*                                                           */
        /*                                                           */
        /*                    OBJECT CAROUSEL                        */
        /*                                                           */
        /*                                                           */
        /* --------------------------------------------------------- */
        .object-carousel {
            display: grid;
            grid-template-columns: 5vw 90vw 5vw;
            grid-template-rows: 5vh 37vh;
        }
        .object-carousel-title {
            font-size: 2.5vw;
        }
    }

    @media (min-width: 1024px) and (orientation: landscape) {
        /* --------------------------------------------------------- */
        /*                                                           */
        /*                                                           */
        /*                    OBJECT CAROUSEL                        */
        /*                                                           */
        /*                                                           */
        /* --------------------------------------------------------- */
        .object-carousel {
            display: grid;
            grid-template-columns: 5vw 90vw 5vw;
            grid-template-rows: 5vh 38vh;
        }
        .object-carousel-title {
            font-size: 2.5vw;
        }
    }

    @media (min-width: 1200px) and (orientation: landscape) {
        /* --------------------------------------------------------- */
        /*                                                           */
        /*                                                           */
        /*                    OBJECT CAROUSEL                        */
        /*                                                           */
        /*                                                           */
        /* --------------------------------------------------------- */
        .object-carousel {
            display: grid;
            grid-template-columns: 5vw 90vw 5vw;
            grid-template-rows: 5vh 38vh;
        }
        .object-carousel-title {
            font-size: 2.25vw;
        }
    }

    @media (min-width: 900px) and (orientation: portrait) {
        .object-carousel {
        grid-template-columns: 5vw 90vw 5vw;
        grid-template-rows: 3vh 37vh;
        }
 
        .object-carousel-title {
            font-size: 5vw;
            padding: 0px;
            margin: 0px;
            height: 7vw;
        }
        .card {
            width: 45vw;
        }
    }    
    
</style>

<script>
import card from './ItemCarouselCard.vue'
export default {
    name: 'ItemCarousel',
    components: {
        card
    },
    props: {
        title: String,
        data : Array
    },
    data() {
        return {
            scroll: 100,
            arrowRight: require('@/assets/ItemCarousel/arrow_black_right.svg'),
            arrowLeft: require('@/assets/ItemCarousel/arrow_black_left.svg'),
            cardsClicked: []
        }
    },
    methods: {
        scrollElements(direction) {
            const carousel = document.getElementById(this.title);
            const amount = 500 * direction; // pixels to move
            const duration = 500; // duration
            const scrollSmoothly = (element, to, duration) => {
                const start = element.scrollLeft;
                const change = to - start;
                const startTime = performance.now();

                const animateScroll = (timestamp) => {
                    const currentTime = timestamp - startTime;
                    const increment = Math.easeInOutQuad(currentTime, start, change, duration);
                    element.scrollLeft = increment;

                    if (currentTime < duration) {
                        requestAnimationFrame(animateScroll);
                    }
                };
                Math.easeInOutQuad = (t, b, c, d) => {
                    t /= d / 2;
                    if (t < 1) return c / 2 * t * t + b;
                    t--;
                    return -c / 2 * (t * (t - 2) - 1) + b;
                };

                requestAnimationFrame(animateScroll);
            };
            scrollSmoothly(carousel, carousel.scrollLeft + amount, duration);
        }
    }

}
</script>